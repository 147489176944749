import React, { useEffect, useState } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import api from '../../services/api';
import ReactLoading from 'react-loading';

import { Header, OrderInfo, Products, LoadingContainer } from './styles';

import LogoImg from '../../assets/logo.png';

interface OrderParams {
  id: string;
}

interface IOrder {
  order: {
    retorno: {
      pedido: {
        id: string;
        numero: string;
        data_pedido: string;
        data_prevista: string;
        data_faturamento: string;
        valor: string;
        situacao: string;
        cliente: {
          nome: string;
        }
        itens: [
          {
            item: {
              codigo: string,
              descricao: string,
              unidade: string,
              quantidade: string,
              valor_unitario: string,
            }
          }
        ]
      }

    }
  }
}

const Order: React.FC = () => {
  const { params } = useRouteMatch<OrderParams>();

  const [order, setOrder] = useState<IOrder | null>(null);
  const [loading, setLoading] = useState(true);

  console.log(order)

  useEffect(() => {
    api.get(`orders/show-order/${params.id}`).then((response) => {
      setOrder(response.data);
    });


  }, [params.id]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <>
      <Header>
        <img src={LogoImg} width={261}  alt="Automação Curitiba" />
        <Link to="/">
          <FiChevronLeft />
          Voltar
        </Link>
      </Header>

      {loading && (
        <LoadingContainer>
          <ReactLoading type="bars" height={447} width={155} color="#EC3E43" />
        </LoadingContainer>
      )}

      {order && !loading && (
        <OrderInfo>
          <header>
            <div>
              <strong>{order.order.retorno.pedido.cliente.nome}</strong>
              <p>data do pedido: {order.order.retorno.pedido.data_pedido}</p>
            </div>
          </header>
          <ul>
            <li>
              <strong>{order.order.retorno.pedido.numero}</strong>
              <span>Número do pedido</span>
            </li>
            <li>
            <strong>{order.order.retorno.pedido.data_prevista}</strong>
              <span>Data de entrega prevista</span>
            </li>
            <li>
              <strong>{order.order.retorno.pedido.situacao}</strong>
              <span>Situação atual</span>
            </li>
          </ul>
        </OrderInfo>
      )}

      {order && !loading && (
        <Products>
        {order?.order.retorno.pedido.itens.map(item => (
          <button
            key={item.item.codigo}
          >
            <div>
              <strong>produto: {item.item.descricao}</strong>
              <p>quantidade: {item.item.quantidade}</p>
            </div>
            <FiChevronRight size={20} />
          </button>
        ))}
      </Products>
      )}
    </>
  );
};

export default Order;
