import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from '../pages/dashboard';
import Order from '../pages/order';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Dashboard} />
    <Route path="/pedido/:id+" component={Order} />

    <Route component={Dashboard} />
  </Switch>
);

export default Routes;
