import React, { useState, FormEvent } from 'react';
import api from '../../services/api';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
// @ts-ignore
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import { Title, Form, Orders, Error } from './styles';

import LogoImg from '../../assets/logo.png';

interface Order {
  order: {
    retorno: {
      pedidos: [
        {
          pedido: {
            id: string;
            numero: string;
            data_pedido: string;
            data_prevista: string;
            valor: string;
            situacao: string;
            nome: string;
          }
        }
      ]
    }
  }
}

const Dashboard: React.FC = () => {
  const [newOrder, setNewOrder] = useState('');
  const [inputError, setInputError] = useState('');
  const [orders, setOrders] = useState<Order>();

  async function handleAddOrder(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!newOrder) {
      setInputError('Digite o seu CNPJ ou CPF');
      return;
    }

    try {
      const parsedNewOrder = newOrder.toString().replace("/", "");

      const response = await api.get<Order>(`orders/find-order/${parsedNewOrder}`);

      const order = response.data;

      setOrders(order);
      setNewOrder('');
      setInputError('');
    } catch (err) {
      setInputError('Erro na busca do CNPJ/CPF');
    }
  }

  return (
    <>
      <img src={LogoImg} width={261}  alt="Automação Curitiba" />
      <Title>Acompanhe os seus pedidos</Title>
      <Form hasError={!!inputError} onSubmit={handleAddOrder}>
        <CpfCnpj
          value={newOrder}
          placeholder="Pesquise o número do seu CNPJ ou CPF"
          onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setNewOrder(e.target.value)}
        />
        <button type="submit">Pesquisar</button>
      </Form>
    
      {inputError && <Error>{inputError}</Error>}

      <Orders>
      {orders?.order.retorno.pedidos.map(order => (
            <Link
              key={order.pedido.numero}
              to={`/pedido/${order.pedido.id}`}
            >
              <div>
                <strong>{order.pedido.data_pedido}</strong>
                <p>{order.pedido.nome}</p>
              </div>

              <FiChevronRight size={20} />
            </Link>
          ))}
      </Orders>
    </>
  );
};

export default Dashboard;
